// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$custom-primary-colors: (
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #0033A0,
  // custom color
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  )
);

$custom-accent-colors: (
  100 : #f7f8bf,
  200 : #f1f494,
  300 : #ebef69,
  400 : #e7eb49,
  500 : #e3e829,
  600 : #e0e524,
  700 : #dce21f,
  800 : #d8de19,
  900 : #d0d80f,
  A100 : #ffffff,
  A200 : #fdffd2,
  A400 : #fbff9f,
  A700 : #faff85,
  contrast: (
    100 : #0033A0,
    200 : #0033A0,
    300 : #0033A0,
    400 : #0033A0,
    500 : #0033A0,
    600 : #0033A0,
    700 : #0033A0,
    800 : #0033A0,
    900 : #0033A0,
    A100 : #0033A0,
    A200 : #0033A0,
    A400 : #0033A0,
    A700 : #0033A0,
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gestautosal-primary: mat.define-palette($custom-primary-colors);
$gestautosal-accent: mat.define-palette($custom-accent-colors);

// The warn palette is optional (defaults to red).
$gestautosal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gestautosal-theme: mat.define-light-theme((color: (primary: $gestautosal-primary,
        accent: $gestautosal-accent,
        warn: $gestautosal-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gestautosal-theme);

/* You can add global styles to this file, and also import other style files */
@import 'assets/theme.scss';

/*
 * Base structure
 */
html {
  position: relative;
  min-height: 100%;
}

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 3.5rem;
  margin-bottom: 60px;
  /*for sticky footer*/
}

/*
   * Typography
   */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid white;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.mat-mdc-table .mdc-data-table__header-row {
  height: 40px;
}

.mat-mdc-table .mdc-data-table__row {
  height: 38px;
}

.mat-mdc-form-field-infix {
  min-height: 10px;
}

.mat-mdc-option {
  min-height: 32px !important;
}

.mat-mdc-raised-button.mat-mdc-button-base {
  height: 30px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #0033A0 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: #ffffff !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #e3e829 !important;
}

.mat-expansion-panel-body {
  padding: 0 5px 5px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Rubik, sans-serif;
}